import { TypeSpecimen } from "@mui/icons-material";

// types of projects
export const typesOfProjects = {
  CPD: "CPD",
  FED: "FED",
};
export const returnedToClientActionEnum = "1021";
export const actionsTypes = {
  // ExhibitorsList: 3268,
  // MarketReport: 3269,
  // InsurancePolicy: 3270,
  ExhibitorsList: 3273,
  MarketReport: 3274,
  InsurancePolicy: 3275,
};
export const FED_TransactionDocumentStatus = {
  // frontend
  Pending: 12378,
  Rejected: 12380,
  Draft: 3272,
  // backend
  PicklistId: 702,
  Submitted: 12379,
  Approved: 12381,
};

export const FED_TransactionDocumentType = {
  PicklistId: 703,
  ExhibitorsList: 3273,
  MarketReport: 3274,
  InsurancePolicy: 3275,
  PreliminaryApproval: 3278,
  MinisterDecision: 3279,
};

// these IDs represent custom dynamic forms, in these form we should:
// 1. request API to check chassis number before submit
// 2. if API returns false we submit the form
// 3. if API returns true we lock the submit and show message to user
export const customTrucksFormsIDs = [
  { 26: "181" },
  { 27: "196" },
  { 28: "203" },
];

export const DynamicFormsTypes = {
  SignatureAndStampNeeded: "SignatureAndStampNeeded",
  DigitalConfirmationNeeded: "DigitalConfirmationNeeded",
  NoConfirmationNeeded: "NoConfirmationNeeded",
};
export const allowedFilesExtensions = [
  ".jpeg",
  ".jpg",
  ".png",
  ".webp",
  ".pdf",
];
export const allowedImagesExtensions = [".jpeg", ".jpg", ".png", ".webp"];
export const AccountTypes = {
  personalAccount: 1,
  companyAccount: 2,
};
