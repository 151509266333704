import axios from "axios";
import environment from "../environment";
import { handleError } from "./error.service";
import { getAccessTokenCookie } from "../utils/cookie";
import { RefreshTokenFunction } from "./refreshToken.services";
import Cookies from "js-cookie";
const token = getAccessTokenCookie();
// "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOiJhNDczMTAzNi1mMTFkLTRjYjktYTJkYS00Njk3NWM5OTBmZWQiLCJVc2VyRW1haWwiOiJmcm9udGVuZEBjbG91ZHN5c3RlbXMudGVjaCIsIkFkbWluIjoiVHJ1ZSIsIlBlcm1lc3Npb25zIjoiMzE2LDExNiw0MTYsMjE2LDMwNCwzMTUsMzEyIiwiQ2xpZW50SWQiOiIiLCJuYmYiOjE2OTE2Njk0NjIsImV4cCI6MTY5MjUzMzQ2MiwiaWF0IjoxNjkxNjY5NDYyLCJpc3MiOiJodHRwczovL2R3LmNsb3Vkc3lzdGVtcy50ZWNoL2FwaS8iLCJhdWQiOiJodHRwczovL2R3LmNsb3Vkc3lzdGVtcy50ZWNoL2FkbWluLyJ9.zF2aLW6fWaOeE_O1tlPD0Spscr9OBNWPVitWWyRuQ9Y";
// const token = localStorage.getItem("TOKEN");

// const userToken = getAccessTokenCookie();
const lang = "en";
const getConfig = (isToken) => {
  if (isToken)
    return {
      headers: {
        Accept_Language: lang,
        // Authorization: `Bearer ${userToken}`,
        Authorization: `Bearer ${Cookies.get("access_token")}`,
      },
    };
  else
    return {
      headers: {
        Accept_Language: lang,
        // APIKey: "F7158EC0C2BB4E8383EB96C24E3C5A92",
        APIKey:
          "46SyX$tm=hR*k*hUHL9dVyqswy3E$3zhS9HD2#xD6Gex+pM=C9Kb#2b%3%@7LD45",
      },
    };
};
const getPublicConfig = (isToken) => {
  const userToken = getAccessTokenCookie();
  if (isToken)
    return {
      headers: {
        Accept_Language: lang,
        // Authorization: `Bearer ${userToken}`,
        Authorization: `Bearer ${Cookies.get("access_token")}`,
      },
    };
  else
    return {
      headers: {
        Accept_Language: lang,
        // APIKey: "F7158EC0C2BB4E8383EB96C24E3C5A92",
        APIKey:
          "46SyX$tm=hR*k*hUHL9dVyqswy3E$3zhS9HD2#xD6Gex+pM=C9Kb#2b%3%@7LD45",
      },
    };
};

const getPostFormConfig = (isToken) => {
  if (isToken)
    return {
      headers: {
        Accept_Language: lang,
        // Authorization: `Bearer ${userToken}`,
        Authorization: `Bearer ${Cookies.get("access_token")}`,
        "Content-Type": 0,
      },
    };
  else
    return {
      headers: {
        Accept_Language: lang,
        APIKey: "F7158EC0C2BB4E8383EB96C24E3C5A92",
        "Content-Type": 0,
      },
    };
};

export const GET = async (apiPath, isToken) => {
  let res = {};
  await axios
    .get(`${environment.API_URL}/${apiPath}`, getConfig(isToken))
    .then((response) => {
      res.status = response.status;
      res.data = response.data;
      if (response.status === 401) {
        RefreshTokenFunction();
      }
    })
    .catch((error) => {
      handleError(error?.response);
    });
  return res;
};
export const CustomGet = async (apiPath, mobileToken) => {
  let res = {};
  await axios
    .get(`${environment.API_URL}/${apiPath}`, {
      headers: {
        Accept_Language: lang,
        Authorization: `Bearer ${mobileToken}`,
      },
    })
    .then((response) => {
      res.status = response.status;
      res.data = response.data;
      if (response.status === 401) {
        RefreshTokenFunction();
      }
    })
    .catch((error) => {
      handleError(error?.response);
    });
  return res;
};
export const GETWITHBODY = async (apiPath, requestBody, isToken) => {
  let res = {};
  await axios
    .get(`${environment.API_URL}/${apiPath}`, requestBody, getConfig(isToken))
    .then((response) => {
      res.status = response.status;
      res.data = response.data;
      if (response.status === 401) {
        RefreshTokenFunction();
      }
    })
    .catch((error) => {
      handleError(error?.response);
    });
  return res;
};

// .get(`${environment.PUBLIC_URL}/${apiPath}`, getPublicConfig(isToken))
export const PUBLICGET = async (apiPath, isToken) => {
  let res = {};
  await axios
    .get(`${environment.API_URL}/${apiPath}`, getPublicConfig(isToken))
    .then((response) => {
      res.status = response.status;
      res.data = response.data;
      if (response.status === 401) {
        RefreshTokenFunction();
      }
    })
    .catch((error) => {
      handleError(error?.response);
    });
  return res;
};

export const PUBLICPOST = async (apiPath, requestBody, isToken) => {
  let res = {};

  // `${environment.PUBLIC_URL}/${apiPath}`,
  await axios
    .post(`${environment.API_URL}/${apiPath}`, requestBody, getConfig(isToken))
    .then((response) => {
      res.status = response.status;
      res.data = response.data;
      if (response.status === 401) {
        RefreshTokenFunction();
      }
    })
    .catch((error) => {
      handleError(error?.response);
    });
  return res;
};
export const PUBLICPUT = async (apiPath, data, isToken) => {
  let res = {};
  await axios
    .put(`${environment.API_URL}/${apiPath}`, data, getPublicConfig(isToken))
    .then((response) => {
      res.status = response.status;
      res.data = response.data;
      if (response.status === 401) {
        RefreshTokenFunction();
      }
    })
    .catch((error) => {
      handleError(error?.response);
    });
  return res;
};
export const PUBLICDELETE = async (apiPath, isToken) => {
  let res = {};
  await axios
    .delete(`${environment.API_URL}/${apiPath}`, getPublicConfig(isToken))
    .then((response) => {
      res.status = response.status;
      res.data = response.data;
      if (response.status === 401) {
        RefreshTokenFunction();
      }
    })
    .catch((error) => {
      handleError(error?.response);
    });
  return res;
};
export const POST = async (apiPath, requestBody, isToken, tok = "") => {
  let res = {};

  await axios
    .post(
      `${environment.API_URL}/${apiPath}`,
      requestBody,
      //  getConfig(isToken)
      {
        headers: {
          Accept_Language: lang,
          // Authorization: `Bearer ${userToken}`,
          Authorization: `Bearer ${tok}`,
        },
      }
    )
    .then((response) => {
      res.status = response.status;
      res.data = response.data;
      if (response.status === 401) {
        RefreshTokenFunction();
      }
    })
    .catch((error) => {
      // return error;
      res.status = error?.response?.status;
      res.data = error?.response?.data;
      // handleError(error?.response);
    });
  return res;
};
export const CustomPost = async (apiPath, requestBody, isToken) => {
  let res = {};
  await axios
    .post(`${environment.API_URL}/${apiPath}`, requestBody, getConfig(isToken))
    .then((response) => {
      res.status = response.status;
      res.data = response.data;
      if (response.status === 401) {
        RefreshTokenFunction();
      }
    })
    .catch((error) => {
      res.status = error?.response?.status;
    });
  return res;
};
export const POSTForm = async (apiPath, requestBody, isToken, tok) => {
  let res = {};
  await axios
    .post(`${environment.API_URL}/${apiPath}`, requestBody, {
      headers: {
        Accept_Language: lang,
        // Authorization: `Bearer ${userToken}`,
        Authorization: `Bearer ${tok}`,
        "Content-Type": 0,
      },
    })
    .then((response) => {
      res.status = response.status;
      res.data = response.data;
      if (response.status === 401) {
        RefreshTokenFunction();
      }
    })
    .catch((error) => {
      handleError(error);
    });
  return res;
};
