import React, { useEffect, useState } from "react";
import { StyledModal } from "./styles";
import { useNavigate } from "react-router-dom";
import {
  GetFEDTransactionDocumentById,
  submitFEDTransactionDocument,
} from "../../../services/fed/fedExhibitors.service";
import { successToaster } from "../../../helpers/toasterConfiguration";
import { insuranceCheckFieldsValidation } from "../../../helpers/FEDFieldsValidations";
import { Button, Skeleton, useMediaQuery, useTheme } from "@mui/material";
import CustomAttachment from "../../elements/attachment";
import {
  actionsTypes,
  FED_TransactionDocumentStatus,
} from "../../../constants/enums";
import RequestInfo from "../request-information";
import { Col, FormLabel, Row } from "react-bootstrap";

const InsurancePolicyModal = (props) => {
  const { show, onHide, transactionId, setRefreshData, documentId } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [data, setData] = useState([
    { label: "رقم الطلب", value: "" },
    { label: "تاريخ الطلب", value: "" },
    { label: "اسم المعرض", value: "" },
    { label: "تاريخ الافتتاح", value: "" },
    { label: "حالة الطلب", value: "" },
  ]);
  // ui / ux
  const [submitLoading, setSubmitLoading] = useState(null);
  const [loading, setLoading] = useState(true);
  const [validateOnChange, setValidateOnChange] = useState(false);
  // fields
  const [insurancePolicyFile, setInsurancePolicyFile] = useState(null);
  const [insurancePolicyFileName, setInsurancePolicyFileName] = useState(null);
  const [
    insurancePolicyFileError,
    setInsurancePolicyFileError,
    insurancePolicyFileErrorRef,
  ] = useState(false);

  // Calling API
  // post data
  const submit = async () => {
    insuranceCheckFieldsValidation(
      insurancePolicyFile,
      setInsurancePolicyFileError
    );
    let isInValid = insurancePolicyFileError;
    if (!isInValid) {
      setSubmitLoading(true);
      let requestBody = {
        TransactionId: transactionId,
        DocumentTypeId: actionsTypes.InsurancePolicy,
        File: insurancePolicyFile,
        FileName: insurancePolicyFileName,
        // ExhibitorsCount: null,
        // VisitorsCount: null,
        // TotalSales: null,
        // Difficulties: "",
      };
      const response = await submitFEDTransactionDocument(requestBody);
      if (response?.status === 200 || response?.status === 204) {
        successToaster(response?.data?.Data);
        setRefreshData((prev) => !prev);
        props.onHide();
      }
      setSubmitLoading(false);
    }
  };
  const getData = async () => {
    setLoading(true);
    const response = await GetFEDTransactionDocumentById(documentId);
    let dataList = [
      { label: "رقم الطلب", value: response?.data?.AutoNumber },
      { label: "تاريخ الطلب", value: response?.data?.TransactionDate },
      { label: "اسم المعرض", value: response?.data?.ExhibitionName },
      { label: "تاريخ الافتتاح", value: response?.data?.OpenDate },
      { label: "حالة الطلب", value: response?.data?.Status },
      { label: "سبب الرفض", value: response?.data?.RejectionReason },
    ];
    if (response?.data?.StatusId == FED_TransactionDocumentStatus?.Rejected) {
      setData(dataList);
      setInsurancePolicyFile(response?.data?.FileUrl);
    } else setData(dataList.slice(0, 5));
    setLoading(false);
  };
  useEffect(() => {
    insuranceCheckFieldsValidation(
      insurancePolicyFile,
      setInsurancePolicyFileError
    );
  }, [insurancePolicyFile]);
  useEffect(() => {
    if (show && documentId) getData();
    setInsurancePolicyFile("");
    setInsurancePolicyFileName("");
    setInsurancePolicyFileError(true);
    setValidateOnChange(false);
  }, [show]);

  return (
    <StyledModal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      direction={"rtl"}
    >
      <StyledModal.Header>
        <h4>رفع نسخة عن بوليصة التأمين</h4>
      </StyledModal.Header>
      <StyledModal.Body>
        {/* <RequestInfo
          autoNumber={data?.AutoNumber}
          transactionDate={data?.TransactionDate}
          exhibitionName={data?.ExhibitionName}
          openDate={data?.OpenDate}
        /> */}
        {data?.map((item, index) => (
          <Row className="w-100 request-info">
            <Col xm="12" sm={"4"} md={"4"} lg={"3"}>
              <FormLabel for={"Name"} className="field-title">
                {item?.label}
              </FormLabel>
            </Col>
            <Col xm="12" sm={"8"} md={"8"} lg={"9"}>
              {loading ? (
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1.2rem", width: "50%" }}
                />
              ) : (
                <FormLabel className="field-value">{item?.value}</FormLabel>
              )}
            </Col>
          </Row>
        ))}

        {loading ? (
          <Skeleton
            sx={{ width: isMobile ? "100%" : "80%", height: "350px" }}
          />
        ) : (
          <CustomAttachment
            label="بوليصة التأمين"
            value={insurancePolicyFile}
            setValue={setInsurancePolicyFile}
            isRequired={true}
            showError={validateOnChange && insurancePolicyFileError}
            setImageName={setInsurancePolicyFileName}
            showImage={true}
          />
        )}
      </StyledModal.Body>
      <StyledModal.Footer>
        <Button
          sx={{
            borderRadius: "15px",
            background:
              "linear-gradient(180deg, var(--first-linear-green) 0%, var(--second-linear-green) 100%)",
            color: "#FFF",
            textAlign: "center",
            fontFamily: "Cairo",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "normal",
            marginLeft: "1rem",
            marginBottom: "1rem",
          }}
          variant="contained"
          onClick={() => {
            setValidateOnChange(true);
            submit();
          }}
          disabled={submitLoading}
        >
          {submitLoading ? (
            <span className="indicator-progress">
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              &nbsp; يتم الحفظ
            </span>
          ) : (
            <>تأكيد</>
          )}
        </Button>
        <Button
          sx={{
            borderRadius: "15px",
            background: "linear-gradient(120deg, #f5f5f5 0%, #9e9e9e 100%)",
            color: "#FFF",
            textAlign: "center",
            fontFamily: "Cairo",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "normal",
            marginLeft: "1rem",
            marginBottom: "1rem",
          }}
          variant="contained"
          onClick={() => onHide()}
          disabled={submitLoading}
        >
          إلغاء
        </Button>
      </StyledModal.Footer>
    </StyledModal>
  );
};

export default InsurancePolicyModal;
